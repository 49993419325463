import { PageRendererProps } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

const FourOhFourPage = (props: PageRendererProps) => {
  return (
    <>
      <Seo metaDescription='You have hit a 404: Not Found page' keywords='' title='404: Not found' />

      <Layout {...props}>
        <h1>404: Not Found</h1>
      </Layout>
    </>
  )
}

export default FourOhFourPage
